/** @jsxImportSource @emotion/react */

import { Outlet } from "react-router-dom";

import { css } from "@emotion/react";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

import NavBar from "../ui-components/NavBar";

import PageFooter from "../ui-components/PageFooter";

export default function PrivatePageLayout() {
	return (
		<>
			<NavBar />

			<Box
				css={css`
					padding-top: 64px;
				`}
			>
				{/* An <Outlet> renders whatever child route is currently active,
						so you can think about this <Outlet> as a placeholder for
						the child routes. */}
				<Outlet />
			</Box>

			<PageFooter></PageFooter>

		</>
	);
}
