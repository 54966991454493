import { initializeApp } from "@firebase/app";
import {
	getAuth,
	setPersistence,
	browserSessionPersistence,
	browserLocalPersistence,
} from "@firebase/auth";

const firebaseConfig = {
	apiKey: "AIzaSyDbLdhdZAl_JH-_3Z5rdDJbobOu0q-joGU",
	authDomain: "saturn-c9b71.firebaseapp.com",
	projectId: "saturn-c9b71",
	storageBucket: "saturn-c9b71.appspot.com",
	messagingSenderId: "108333578866",
	appId: "1:108333578866:web:7624aedac0baac04b6e0eb"
};

const fbApp = initializeApp(firebaseConfig);

const fbAuth = getAuth(fbApp);

setPersistence(fbAuth, browserLocalPersistence);

export default fbAuth;
