/** @jsxImportSource @emotion/react */

import NavBarActive from "../ui-components/NavBarActive";
import LandingFullPageWelcome from "./LandingFullPageWelcome";
import LandingFeatures from "./LandingFeatures";
import LandingNumbers from "./LandingNumbers";
import SubscribeUs from "./SubscribeUs";
import PageFooter from "../ui-components/PageFooter";



export default function Landing() {

  return (
    <>
      <NavBarActive />

      <LandingFullPageWelcome></LandingFullPageWelcome>

      <LandingFeatures></LandingFeatures>

      <LandingNumbers></LandingNumbers>

      <SubscribeUs></SubscribeUs>

      <PageFooter></PageFooter>
    </>
  );
}
