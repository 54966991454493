import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Material-UI imports
import { ThemeProvider } from "@mui/material/styles";
import theme from "../styles/theme";
import CssBaseline from "@mui/material/CssBaseline";

// Page layouts
import PublicPageLayout from "../layouts/PublicPageLayout";
import PrivatePageLayout from "../layouts/PrivatePageLayout";

// Pages
import Landing from "../pages/Landing";
import Main from "../pages/saturn/Main";
import SignIn from "../pages/SignIn";
import ContactUs from "../pages/ContactUs";
import TermsOfUse from "../pages/TermsOfUse";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import AboutUs from "../pages/AboutUs";
import DigitalConsulting from "../pages/DigitalConsulting";
import Cybersecurity from "../pages/Cybersecurity";
import AssessmentRequest from "../pages/AssessmentRequest";

// 404 Pages
import Saturn404 from "../pages/saturn/Saturn404";
import Public404 from "../pages/Public404";

// Auth utils
import AuthProvider from "../utils/AuthProvider";
import RequestInterceptor from "../utils/RequestInterceptor"; // Axios interceptor
import RequireAuth from "../utils/RequireAuth";
import ProtectedPage from "../pages/Protected";

import ScrollToTop from "../utils/ScrollToTop";

import ReactGA from 'react-ga';

import { useLocation } from 'react-router-dom';
import { useEffect } from "react";

const TRACKING_ID = "UA-223917800-2"; 

ReactGA.initialize(TRACKING_ID);



export default function App() {
	
	let location = useLocation();

	useEffect(() => {
		ReactGA.ga('send', 'pageview');
	  }, [location]);	

	return (
			<>
			<ScrollToTop />
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<AuthProvider>
					<Pages />
				</AuthProvider>
			</ThemeProvider>
			</>
	);
}

function Pages() {
	return (
		<Routes>
			<Route path="/" element={<Landing />} />
			<Route path="/request-assessment" element={<AssessmentRequest />} />
			<Route path="/" element={<PublicPageLayout />}>				

				<Route path="/signin" element={<SignIn />} />

				<Route path="/contact" element={<ContactUs />} />

				<Route path="/about" element={<AboutUs />} />

				<Route path="/terms" element={<TermsOfUse />} />
				
				<Route path="/privacy" element={<PrivacyPolicy />} />

				<Route path="/digital-consulting" element={<DigitalConsulting />} />

				<Route path="/cybersecurity" element={<Cybersecurity />} />				

				{/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
				<Route path="*" element={<Public404 />} />
			</Route>

			{/* This is the basis route for the main Saturn app, authenticated access only. */}
			<Route
				path="/saturn"
				element={
					<RequireAuth>
						<RequestInterceptor>
							<PrivatePageLayout />
						</RequestInterceptor>
					</RequireAuth>
				}
			>
				<Route index element={<Main />} />

				<Route path="protected" element={<ProtectedPage />} />

				{/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
				<Route path="*" element={<Saturn404 />} />
			</Route>			

		</Routes>
	);
}
