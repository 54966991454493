/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";

import Typography from "@mui/material/Typography";

import Container from "@mui/material/Container";

export default function TermsOfUse() {
	return (
		<>
			<Container
				maxWidth="xl"
				css={css`
					padding-top: 32px;
					padding-bottom: 48px;
				`}
			>
				<Typography
					component="h1"
					variant="h3"
					gutterBottom
					align="center"
				>
					Novaleap Terms of Use
				</Typography>
				<Typography component="div" variant="body1" gutterBottom>
					<p>
						This agreement governs the customer's use of Website and
						Services of Novaleap Consulting. By accessing this
						Website or using our Services, you agree to these Terms
						on behalf of yourself or any entity you represent.
					</p>
					<p>
						We may update or revise these Terms at any time without
						notice. We recommend that you visit this section
						regularly to ensure your compliance.
					</p>
					<p>
						You must be at least 18 years old to use our Website and
						Services. By using the Novaleap Website and Services
						(and agreeing to these Terms), you warrant and represent
						that you are at least 18 years of age.
					</p>
					<p>
						We also use cookies. This means that by using our
						Website you accept these Terms and consent to our cookie
						policy in accordance with our privacy policy, which can
						be found in the Privacy Policy section on the Website.
					</p>
				</Typography>
				<Typography component="h2" variant="h4" gutterBottom>
					How to use this Website
				</Typography>
				<Typography component="div" variant="body1" gutterBottom>
					<p>
						You may not use the Novaleap Website in any way that may
						harm the Website or impair its accessibility; or in any
						way that is illegal, unlawful, or harmful, or in
						connection with any illegal, illegal, or fraudulent
						activity.
					</p>
					<p>
						You must not use this Website to copy, store, host,
						transmit, use, publish or distribute any material which
						consists of (or is related to) any spyware, computer
						virus, keystroke logger, trojan horse or any other
						malicious computer software.
					</p>
					<p>
						We provide our Website users with useful information
						about Novaleap Services, as well as marketing,
						informational and educational content. Therefore,
						Novaleap retains all intellectual property rights in all
						content posted on our website, whether or not it is
						publicly available under these Terms.
					</p>
					<p>
						Your use of our content material, including documents,
						graphics, case studies and others, is limited to
						downloading, displaying, copying and printing under your
						personal and non-commercial use.
					</p>
					<p>
						If your use of our content is deemed "fair use" and
						complies with copyright law, you may not use, reproduce,
						download, copy, display, publish, transmit or distribute
						any information on the Novaleap Website, in whole or in
						part, without notice.
					</p>
				</Typography>
				<Typography component="h2" variant="h4" gutterBottom>
					Termination of the Website use
				</Typography>
				<Typography component="div" variant="body1" gutterBottom>
					<p>
						These terms remain in effect for as long as you are
						using the Website. Novaleap may, at any time, for any
						reason and in its sole discretion, terminate your rights
						to use this Website or any service accessed through the
						Website, including your account, in breach of these
						Terms.
					</p>
					<p>
						Upon expiration of your rights under these Terms, your
						account and your right to access and use the Website
						will be terminated immediately and indefinitely. Any
						termination of your account may require the deletion of
						your content associated with your account from our
						databases.
					</p>
				</Typography>
				<Typography component="h2" variant="h4" gutterBottom>
					Passwords and security
				</Typography>
				<Typography component="div" variant="body1" gutterBottom>
					<p>
						You and only you are responsible for maintaining the
						confidentiality of the password(s) obtained to access
						our Website and Services and you are solely responsible
						for all activities that occur under your password(s).
						Subject to these Terms, you agree to immediately notify
						Novaleap of any unauthorized or suspicious use of your
						passwords.
					</p>
				</Typography>
				<Typography component="h2" variant="h4" gutterBottom>
					Links to third party sites
				</Typography>
				<Typography component="div" variant="body1" gutterBottom>
					<p>
						If you browse our website, you may find links that allow
						you to leave our website. Linked websites are not
						regulated or controlled by Novaleap and therefore we are
						not responsible for the contents of any linked website
						or any link contained in a linked website. Novaleap is
						not responsible for any information or transmissions
						received from any linked website.
					</p>
				</Typography>
			</Container>
		</>
	);
}
