/** @jsxImportSource @emotion/react */

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import WelcomeName from "./WelcomeName";
import SignInSignOutButton from "./SignInSignOutButton";

import Box from "@mui/material/Box";

import { Typography } from "@mui/material";

import { css } from "@emotion/react";

import React from "react";

import { Link as RouterLink } from "react-router-dom";

import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";

import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";

import IconButton from "@mui/material/IconButton";

const linksArray = [
	{
		key: 0,
		label: "Digital Consulting",
		link: "/digital-consulting",
	},
	{
		key: 1,
		label: "Cybersecurity",
		link: "/cybersecurity",
	},
];

export default function NavBar() {
	const [anchorElNav, setAnchorElNav] = React.useState(null);

	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	return (
		<React.Fragment>
			<AppBar
				css={css`
					background-color: #fff;
					border-bottom: 1px solid #dadada;
					transition: all 0.2s ease !important;
				`}
				position="fixed"
				elevation={0}
			>
				<Container maxWidth="xl">
					<Toolbar
						disableGutters
						css={css`
							height: 64px;
						`}
					>
						<RouterLink
							to="/"
							css={css`
								text-decoration: none;
								display: flex;
								align-items: center;
							`}
						>
							<img
								height="33"
								src="/static/images/icons/NLLOGORED.svg"
								css={css`
									display: block;
								`}
							/>
							<Typography
								component="span"
								css={css`
									font-family: 'Roboto Condensed',
										sans-serif;
									font-size: 30px;
									font-weight: 400;
									color: #eb4d36;
									margin-left: 8px;
									display: block;
								`}
							>
								Novaleap
							</Typography>
						</RouterLink>
						<Box
							sx={{
								flexGrow: 1,
								justifyContent: "flex-end",
								alignItems: "center",
								display: { xs: "flex", md: "none" },
							}}
						>
							<IconButton
								size="large"
								aria-label="account of current user"
								aria-controls="menu-appbar"
								aria-haspopup="true"
								onClick={handleOpenNavMenu}
								color="primary"
							>
								<MenuIcon />
							</IconButton>
							<Menu
								id="menu-appbar"
								anchorEl={anchorElNav}
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "left",
								}}
								keepMounted
								transformOrigin={{
									vertical: "top",
									horizontal: "left",
								}}
								open={Boolean(anchorElNav)}
								onClose={handleCloseNavMenu}
								sx={{
									display: { xs: "block", md: "none" },
								}}
							>
								{linksArray.map((item) => (
									<MenuItem
										key={item.key}
										onClick={handleCloseNavMenu}
										component={RouterLink}
										to={item.link}
									>
										{item.label}
									</MenuItem>
								))}
								<Divider />
								<MenuItem
									key="99"
									onClick={handleCloseNavMenu}
									component={RouterLink}
									to="/signin"
								>
									Signin
								</MenuItem>
							</Menu>
						</Box>

						<Box
							sx={{
								flexGrow: 1,
								justifyContent: "flex-end",
								alignItems: "center",
								display: { xs: "none", md: "flex" },
							}}
						>
							{linksArray.map((item) => (
								<RouterLink
									css={css`
										display: block;
										margin-right: 24px;
										color: #2c2c2c;
										text-decoration: none;
									`}
									to={item.link}
								>
									{item.label}
								</RouterLink>
							))}

							<WelcomeName />

							<SignInSignOutButton />
						</Box>
					</Toolbar>
				</Container>
			</AppBar>
		</React.Fragment>
	);
}
