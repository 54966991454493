/** @jsxImportSource @emotion/react */

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { css } from "@emotion/react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { useState } from "react";

import { TextField } from "@mui/material";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Controller, useForm } from "react-hook-form";

import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";

import { useCreateAssessmentRequestMutation } from "../services/saturnAPI";

import { ThemeProvider } from "@mui/material/styles";

import darkTheme from "../styles/darkTheme";



export default function FullPageAssessmentRequest() {
	const fullpageImage = "/static/images/stock-photos/road-start1.jpeg";

	const [orgSize, setOrgSize] = useState("");

	const handleChange = (event) => {
		setOrgSize(event.target.value);
	};

	const {
		handleSubmit,
		setError,
		reset,
		clearErrors,
		control,
		formState: { errors },
	} = useForm({
		defaultValues: {
			fullName: "",
			email: "",
			organizationName: "",
			phoneNumber: "",
			jobTitle: "",
			website: "",
			organizationSize: ""
		},
	});

	const [
		createAssessmentRequest,
		{
			error: createAssessmentRequestIsError,
			isLoading: createAssessmentRequestIsLoading,
		},
	] = useCreateAssessmentRequestMutation();

	const onSubmit = (formData, e) => {
		e.preventDefault();
		createAssessmentRequest({
			fullName: formData.fullName.trim(),
			email: formData.email.trim(),
			organizationName: formData.organizationName.trim(),
			jobTitle: formData.jobTitle.trim(),
			website: formData.website.trim(),
			phoneNumber: formData.phoneNumber.trim(),						
			staffHeadcount: orgSize,
		})
			.unwrap()
			.then((payload) => {
				toast.success(
					"Thank you for your assessment request. We will contact you shortly.",
					{
						autoClose: false,
						position: "bottom-right"
					}
				);
				reset();
				setOrgSize(null);
			})
			.catch((error) => {
				toast.warn(error.response, { autoClose: false, position: "bottom-right" });
			});
	};

	return (
		<>
			<ThemeProvider theme={darkTheme}>
			<Box
					css={css`
						position: relative;
						z-index: 1;
						&:before {
							background-image: linear-gradient(
								0deg,
								rgba(0, 0, 0, 0) 0%,
								rgba(21, 27, 38, 0.5) 100%
							);
							background-repeat: repeat-x;
							position: absolute;
							top: 0;
							right: 0;
							bottom: 0;
							left: 0;
							z-index: -1;
							width: 100%;
							height: 100%;
							content: "";
						}
						&:after {
							position: absolute;
							top: 0;
							right: 0;
							bottom: 0;
							left: 0;
							z-index: -1;
							width: 100%;
							height: 100%;
							content: "";
						}

						background-image: url("${fullpageImage}");
						background-size: cover;
						background-repeat: no-repeat;
						background-position: top center;
						background-color: #000;
					`}
				>
					<Container
						maxWidth="xl"
						css={css`
							min-height: calc(100vh + 112px);
							padding-top: 64px;
						`}
					>
						<Grid
							container
							spacing={0}
							justifyContent="center"
							alignItems="center"
							css={css`
								min-height: 100vh;
							`}
						>
							<Grid item sm={10}>
								<Box>
									<Grid
										container
										spacing={2}
										alignItems="center"
										justifyContent="flex-start"
									>
										<Grid item xs={12} md={6}>
											<Box
												css={css`
													background: rgba(
														0,
														0,
														0,
														0.5
													);
													min-height: 200px;
													display: flex;
													justify-content: center;
													padding: 24px;
												`}
											>
												<form
													onSubmit={handleSubmit(
														onSubmit
													)}
												>
													<ToastContainer />
													<Grid
														container
														spacing={2}
														justifyContent="center"
													>
														<Grid
															item
															xs={12}
														>
															<Typography
																component="h1"
																variant="h1"
																gutterBottom
																align="center"
																css={css`
																	color: #fff;
																`}
															>
																Request an
																Assessment
															</Typography>
														</Grid>
														<Grid
															item
															xs={12}
															md={6}
														>
															<Controller
																name="fullName"
																control={
																	control
																}
																defaultValue=""
																render={({
																	field: {
																		onChange,
																		value,
																	},
																	fieldState:
																		{
																			error,
																		},
																}) => (
																	<TextField
																		label="Full name"
																		fullWidth
																		value={
																			value
																		}
																		onChange={
																			onChange
																		}
																		error={
																			!!error
																		}
																		helperText={
																			error
																				? error.message
																				: null
																		}
																	/>
																)}
																rules={{
																	required:
																		"Full name is required.",
																}}
															/>
														</Grid>
														<Grid
															item
															xs={12}
															md={6}
														>
															<Controller
																name="email"
																control={
																	control
																}
																defaultValue=""
																render={({
																	field: {
																		onChange,
																		value,
																	},
																	fieldState:
																		{
																			error,
																		},
																}) => (
																	<TextField
																		label="E-mail"
																		fullWidth
																		value={
																			value
																		}
																		onChange={
																			onChange
																		}
																		error={
																			!!error
																		}
																		helperText={
																			error
																				? error.message
																				: null
																		}
																	/>
																)}
																rules={{
																	required:
																		"E-mail is required.",
																	pattern: {
																		value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
																		message:
																			"Please enter a valid e-mail address.",
																	},
																}}
															/>
														</Grid>
													</Grid>
													<Grid
														container
														spacing={2}
														justifyContent="center"
														sx={{ pt: 2 }}
													>
														<Grid
															item
															xs={12}
														>
															<Controller
																name="organizationName"
																control={
																	control
																}
																defaultValue=""
																render={({
																	field: {
																		onChange,
																		value,
																	},
																	fieldState:
																		{
																			error,
																		},
																}) => (
																	<TextField
																		label="Organization name"
																		fullWidth
																		value={
																			value
																		}
																		onChange={
																			onChange
																		}
																		error={
																			!!error
																		}
																		helperText={
																			error
																				? error.message
																				: null
																		}
																	/>
																)}
																rules={{
																	required:
																		"Organization name is required.",
																}}
															/>
														</Grid>
														<Grid
															item
															xs={12}
														>
															<Controller
																name="jobTitle"
																control={
																	control
																}
																defaultValue=""
																render={({
																	field: {
																		onChange,
																		value,
																	},
																	fieldState:
																		{
																			error,
																		},
																}) => (
																	<TextField
																		label="Job title"
																		fullWidth
																		value={
																			value
																		}
																		onChange={
																			onChange
																		}
																		error={
																			!!error
																		}
																		helperText={
																			error
																				? error.message
																				: null
																		}
																	/>
																)}
																rules={{
																	required:
																		"Job title is required.",
																}}
															/>
														</Grid>
														<Grid
															item
															xs={12}
														>
															<Controller
																name="website"
																control={
																	control
																}
																defaultValue=""
																render={({
																	field: {
																		onChange,
																		value,
																	},
																	fieldState:
																		{
																			error,
																		},
																}) => (
																	<TextField
																		label="Website URL"
																		fullWidth
																		value={
																			value
																		}
																		onChange={
																			onChange
																		}
																		error={
																			!!error
																		}
																		helperText={
																			error
																				? error.message
																				: null
																		}
																	/>
																)}
															/>
														</Grid>
														<Grid
															item
															xs={12}
															sm={6}
														>
															<Controller
																name="organizationSize"
																control={
																	control
																}
																defaultValue=""
																render={({
																	field: {
																		onChange,
																		value,
																	},
																	fieldState:
																		{
																			error,
																		},
																}) => (
																	<FormControl
																		fullWidth
																	>
																		<InputLabel id="demo-simple-select-label">
																			Staff
																			headcount
																		</InputLabel>
																		<Select
																			labelId="demo-simple-select-label"
																			id="demo-simple-select"
																			value={
																				orgSize
																			}
																			label="Staff headcount"
																			onChange={
																				handleChange
																			}
																		>
																			<MenuItem
																				value={
																					"micro"
																				}
																			>
																				{
																					"< 10"
																				}
																			</MenuItem>
																			<MenuItem
																				value={
																					"small"
																				}
																			>
																				{
																					"< 50"
																				}
																			</MenuItem>
																			<MenuItem
																				value={
																					"medium"
																				}
																			>
																				{
																					"< 250"
																				}
																			</MenuItem>
																			<MenuItem
																				value={
																					"large"
																				}
																			>
																				{
																					"> 250"
																				}
																			</MenuItem>
																		</Select>
																	</FormControl>
																)}
															/>
														</Grid>
														<Grid
															item
															xs={12}
															sm={6}
														>
															<Controller
																name="phoneNumber"
																control={
																	control
																}
																defaultValue=""
																render={({
																	field: {
																		onChange,
																		value,
																	},
																	fieldState:
																		{
																			error,
																		},
																}) => (
																	<TextField
																		label="Phone number"
																		fullWidth
																		value={
																			value
																		}
																		onChange={
																			onChange
																		}
																		error={
																			!!error
																		}
																		helperText={
																			error
																				? error.message
																				: null
																		}
																	/>
																)}
															/>
														</Grid>
													</Grid>
													<Grid
														container
														spacing={2}
														justifyContent="center"
														sx={{ pt: 2 }}
													>
														<Grid
															item
															xs={12}
															sm={12}
															align="center"
														>
															<Typography
																variant="body2"
																gutterBottom
																component="div"
																css={css`
																	color: #fff;
																`}
															>
																<p>
																	By supplying
																	my contact
																	information,
																	I authorize
																	Novaleap to
																	contact me
																	with
																	personalized
																	communications
																	about
																	products and
																	services.
																</p>
																See our{" "}
																<Link
																	component={
																		RouterLink
																	}
																	to="/privacy-policy"
																>
																	Privacy
																	Policy
																</Link>{" "}
																for more
																details.
															</Typography>
														</Grid>
													</Grid>
													<Grid
														container
														spacing={2}
														justifyContent="center"
														sx={{ pt: 2 }}
													>
														<Grid
															item
															xs={12}
															sm={6}
															align="center"
														>
															<Button
																variant="contained"
																size="large"
																color="primary"
																type="submit"
															>
																Submit
															</Button>
														</Grid>
													</Grid>
												</form>
											</Box>
										</Grid>
									</Grid>
								</Box>
							</Grid>
						</Grid>
					</Container>
			</Box>
			</ThemeProvider>
		</>
	);
}
