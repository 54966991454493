import React, { useContext } from "react";

import { AuthContext } from "./AuthContext";

import { Navigate, useLocation } from "react-router-dom";

export default function RequireAuth({ children }) {
	const { user } = useContext(AuthContext);

	const location = useLocation();

	if (!user) {
		// Redirect to the /signin page, but save the current location they were
		// trying to go to when they were redirected. This allows us to send them
		// along to that page after they signin, which is a nicer user experience
		// than dropping them off on the home page.
		return <Navigate to="/signin" state={{ from: location }} />;
	}

	return children;
}