/** @jsxImportSource @emotion/react */

import Typography from "@mui/material/Typography";

import { css } from "@emotion/react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import { TextField, Button } from "@mui/material";

import { useState } from "react";

import styled from "@emotion/styled";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useSubscribeToNewsletterMutation } from "../services/saturnAPI";

import { Controller, useForm } from "react-hook-form";

const CssTextField = styled(TextField)({
	"& input": {
		color: "#FFFFFF",
	},
	"& label": {
		color: "#FFFFFF",
	},
	"& label.Mui-focused": {
		color: "#FFFFFF",
	},
	"& .MuiInput-underline:after": {
		borderBottomColor: "#FFFFFF",
	},
	"& .MuiOutlinedInput-root": {
		"& fieldset": {
			borderColor: "#FFFFFF",
		},
		"&:hover fieldset": {
			borderColor: "#FFFFFF",
		},
		"&.Mui-focused fieldset": {
			borderColor: "#FFFFFF",
		},
	},
});

export default function SubscribeUs() {
	const [subsEmail, setSubsEmail] = useState("");

	const [emailError, setEmailError] = useState(false);

	const [
		createSubscription, // This is the mutation trigger
		{
			error: createSubscriptionIsError,
			isLoading: createSubscriptionIsLoading,
		}, // This is the destructured mutation result
	] = useSubscribeToNewsletterMutation();

	const {
		handleSubmit,
		setError,
		clearErrors,
		control,
		formState: { errors },
	  } = useForm();	

	const onSubmit = (formData, e) => {
		try {
			var pattern =
				/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

			if (!pattern.test(subsEmail)) {
				setEmailError(true);
				e.preventDefault();
				toast.warn("Please enter a valid e-mail address.", { autoClose: false, position: "bottom-right" });
			} else {			
				createSubscription({
					email: subsEmail
				})
					.unwrap()
					.then((payload) => {
						toast.success(
							"Thank you for subscribing. A confirmation e-mail has been sent to your e-mail address.",
							{ autoClose: false, position: "bottom-right" }
						);
						setEmailError(false);				
					})
					.catch((error) => {
						console.log(error);
						setEmailError(true);
						e.preventDefault();
						toast.warn(error.response);
					});
			}
		} catch (error) {
			setEmailError(true);
			e.preventDefault();
			toast.warn("Unexpected error.", { autoClose: false, position: "bottom-right" });
		}
	};

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Box
					css={css`
						background-color: #fff;
						padding-top: 60px;
						padding-bottom: 60px;
						background-image: url("/static/images/stock-photos/dots3.png");
						background-size: cover;
						background-repeat: no-repeat;
						background-position: top center;
						min-height: 300px;
					`}
				>
					<Container maxWidth="xl">
						<Box sx={{ paddingBottom: "20px" }}>
							<Typography
								gutterBottom
								variant="h1"
								component="div"
								color="common.white"
							>
								Subscribe Us
							</Typography>
							<Typography
								gutterBottom
								component="div"
								color="common.white"
							>
								Subscribe to our newsletter to receive insights
								and hear about latest features of the platform.
							</Typography>
						</Box>

						<Grid container spacing={2} alignItems="center">
							<Grid item xs={12} sm={3}>
								<CssTextField
									error={emailError}
									size="small"
									fullWidth
									label={"E-mail"}
									onChange={(event) => {
										setSubsEmail(event.target.value);
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={3}>
								<Button
									variant="contained"
									size="large"
									color="primary"
									type="submit"
								>
									Submit
								</Button>
								<ToastContainer />
							</Grid>
						</Grid>
					</Container>
				</Box>
			</form>
		</>
	);
}
