import React, { useState, useEffect, useContext, createContext } from "react";

import { AuthContext } from "./AuthContext";
import fbAuth from "./firebaseAuth";

import { onAuthStateChanged } from "@firebase/auth";

export default function AuthProvider({ children }) {
	const [user, setUser] = useState(null);
	const [initializing, setInitializing] = useState(true);

	useEffect(() => {
		onAuthStateChanged(fbAuth, (currentUser) => {
			setUser(currentUser);
			setInitializing(false);
		});
	}, []);

	const contextValue = {
		user,
		initializing,
	};


	return (
		<div>
			{initializing ? (
				<div>Novaleap</div>
			) : (
				<AuthContext.Provider value={contextValue}>
					{children}
				</AuthContext.Provider>
			)}
		</div>
	);
}
