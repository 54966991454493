/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";

import Typography from "@mui/material/Typography";

import Container from "@mui/material/Container";

export default function PrivacyPolicy() {
	return (
		<>
			<Container
				maxWidth="xl"
				css={css`
					padding-top: 32px;
					padding-bottom: 48px;
				`}
			>
				<Typography
					component="h1"
					variant="h3"
					gutterBottom
					align="center"
				>
					Privacy Policy
				</Typography>

				<Typography component="div" variant="body1" gutterBottom>
					<p>
						Novaleap Consulting is committed to ensuring that the
						collection and processing of your data, carried out from
						the site www.novaleap.com meets the requirements of the
						Netherlands and EU.
					</p>
					<p>
						With this Privacy Policy, we aim to transparently
						explain how and why we gather, store, share and use your
						personal data in a manner consistent with the laws. Your
						privacy and the security of your personal data is, and
						will always be, enormously important to us.
					</p>
					<p>
						This Privacy Policy applies to websites, mobile
						applications, and any other online or offline services
						provided by Novaleap Consulting. It also applies to our
						marketing and advertising activities on all platforms
						and other services that we may provide to you from time
						to time. In this Privacy Policy, we refer to our
						websites, marketing/advertising activities and other
						services collectively as our "Services".
					</p>
				</Typography>

				<Typography component="h2" variant="h4" gutterBottom>
					Consent
				</Typography>

				<Typography component="div" variant="body1" gutterBottom>
					<p>
						By using our Services, you agree and accept that we may
						gather, process, store and/or use the personal data
						submitted in accordance with the rules set forth below.
					</p>
					<p>
						By giving your consent to us, you also retain the right
						to have your personal data rectified, to be forgotten
						and/or to be erased.
					</p>
					<p>
						We provide our Website users with useful information
						about Novaleap Services, as well as marketing,
						informational and educational content. Therefore,
						Novaleap retains all intellectual property rights in all
						content posted on our website, whether or not it is
						publicly available under these Terms.
					</p>
					<p>
						If you have any other concerns about providing data to
						us or it being used as described in this Privacy Policy
						you should not use our Services.
					</p>
				</Typography>

				<Typography component="h2" variant="h4" gutterBottom>
					Personal data collected and processed
				</Typography>

				<Typography component="div" variant="body1" gutterBottom>
					<p>
						We process personal data for a variety of purposes which
						are stated below. Personal data is all information about
						an identified or identifiable person. This includes the
						following categories of personal data that we process:
					</p>
					<ul>
						<li>
							Personal and professional contact details: first
							name, last name, email address, telephone, company
							name, country, job title
						</li>
						<li>
							Log files with information about your access to our
							Services
						</li>
						<li>
							Online identifiers (such as cookie IDs, IP
							addresses, unique device identifier advertising IDs)
						</li>
						<li>
							Individuals who visit our social media sites, social
							media plugins and tools
						</li>
						<li>Job applicants</li>
						<li>
							Usage, viewing, technical, and device data when you
							visit our sites, use our tools, or open emails we
							send, or connect with our wireless Internet access
							services and other similar technologies.
						</li>
					</ul>
					<p>
						Contact requests are kept for the time necessary to
						respond to you. This data is stored in the database for
						use for commercial purposes only if you accept this by
						checking the consent box at the bottom of the form.
					</p>
					<p>
						In no case does Novaleap Consulting sell, share or rent
						out your contacts to third parties, nor does it use them
						for any purpose other than those set forth in this
						policy. We will use the information from your contacts
						only for legal requirements, to invoice and collect
						summaries for our own statistics and for the purposes of
						providing you with customer support services.
					</p>
				</Typography>

				<Typography component="h2" variant="h4" gutterBottom>
					Newsletter and marketing emails
				</Typography>

				<Typography component="div" variant="body1" gutterBottom>
					<p>
						An unsubscribe link should be included in every
						newsletter and marketing email sent by you through our
						platform. For those that have expressly opted in to
						receive our Novaleap newsletter, you are easily able to
						unsubscribe by following the "unsubscribe" links
						included in every email or by contacting us directly.
					</p>
				</Typography>

				<Typography component="h2" variant="h4" gutterBottom>
					Cookies
				</Typography>

				<Typography component="div" variant="body1" gutterBottom>
					<p>
						Cookies are small pieces of information that are stored
						on your computer. Cookies enable us to collect anonymous
						information about the usage of our website and to
						improve and adjust it to the wants of our visitors. The
						cookie does not contain personal data. Cookies are
						stored locally on the computer or telephone.
					</p>
					<p>
						The cookies that Novaleap Consulting uses can be
						categorized in functional, analytical and tracking
						cookies.
					</p>
					<ol>
						<li>
							<p>Functional cookies</p>
							<p>
								Functional cookies are indispensable for the
								proper functioning of our website, such as an
								automatic log-in or the auto-fill of your data.
								Also, your cookie preference is saved.
							</p>
						</li>
						<li>
							<p>Analytical cookies</p>
							<p>
								These cookies enable us to measure the surfing
								behavior of visitors on our website in order to
								increase usability.
							</p>
						</li>
						<li>
							<p>Tracking cookies (first and third-party)</p>
							<p>
								Cookies that are used to record surfing behavior
								so that it is possible to show relevant
								advertisements or cookies across multiple
								domains.
							</p>
						</li>
					</ol>
				</Typography>

				<Typography component="h2" variant="h4" gutterBottom>
					Blocking cookies
				</Typography>

				<Typography component="div" variant="body1" gutterBottom>
					<p>
						If you block cookies on www.novaleap.com, you can still
						visit this website. However, it is possible that you no
						longer can use all parts or functionalities of the
						website.
					</p>
				</Typography>

				<Typography component="h2" variant="h4" gutterBottom>
					Delete or disable cookies
				</Typography>

				<Typography component="div" variant="body1" gutterBottom>
					<p>
						If you do not want to receive any cookies, you can also
						change the settings of your internet browser to block
						cookies entirely.
					</p>
				</Typography>

				<Typography component="div" variant="body1" gutterBottom>
					<p>
						For support please e-mail us at privacy at novaleap.com
					</p>
				</Typography>
			</Container>
		</>
	);
}
