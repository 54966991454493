/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";

import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import { Grid, TextField, Paper, Box, Container, Stack } from "@mui/material";

import fbAuth from "../utils/firebaseAuth";

import { signInWithEmailAndPassword } from "@firebase/auth";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Controller, useForm } from "react-hook-form";

export default function SignIn() {
	let navigate = useNavigate();

	const [signInEmail, setSignInEmail] = useState("");
	const [signInPassword, setSignInPassword] = useState("");

	// Catch errors with toastify here
	const signIn = async () => {
		try {
			var pattern =
				/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

			if (!pattern.test(signInEmail)) {
				toast.warn("Please enter a valid e-mail address.");
			} else if (signInPassword === "") {
				toast.warn("Please enter your password.");
			} else {
				const userCred = await signInWithEmailAndPassword(
					fbAuth,
					signInEmail,
					signInPassword
				);
				navigate("/saturn");
			}
		} catch (error) {
			toast.error(error.message);
		}
	};

	const {
		handleSubmit,
		setError,
		reset,
		clearErrors,
		control,
		formState: { errors },
	} = useForm({
		defaultValues: {
			fullName: "",
			email: "",
			organizationName: "",
			phoneNumber: "",
			message: "",
		},
	});

	const onSubmit = async (formData, e) => {
		e.preventDefault();

		try {
			const userCred = await signInWithEmailAndPassword(
				fbAuth,
				signInEmail,
				signInPassword
			);
			navigate("/saturn");
		} catch (error) {
			toast.error(error.message);
		}
		
	};

	return (
		<Box
			css={css`
				padding-top: 60px;
				padding-bottom: 30px;
				min-height: calc(100vh - 388px);
			`}
		>
			<Container maxWidth="xl">
				<Grid
					container
					spacing={0}
					justifyContent="space-around"
					alignItems="center"
				>
					<Grid item xs={12} sm={4} justifyContent="center">
						<form onSubmit={handleSubmit(onSubmit)}>
							<ToastContainer />
							<Stack spacing={2}>
								<Box>
									{" "}
									<Typography
										component="h1"
										variant="h3"
										gutterBottom
										align="center"
									>
										Sign In
									</Typography>
								</Box>
								<Box>
									{" "}
									<Controller
										name="email"
										control={control}
										defaultValue=""
										render={({
											field: { onChange, value },
											fieldState: { error },
										}) => (
											<TextField
												label="E-mail"
												fullWidth
												value={value}
												onChange={onChange}
												error={!!error}
												helperText={
													error ? error.message : null
												}
											/>
										)}
										rules={{
											required: "E-mail is required.",
											pattern: {
												value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
												message:
													"Please enter a valid e-mail address.",
											},
										}}
									/>
								</Box>
								<Box>
									<Controller
										name="password"
										control={control}
										defaultValue=""
										render={({
											field: { onChange, value },
											fieldState: { error },
										}) => (
											<TextField
												type={"password"}
												label="Password"
												fullWidth
												value={value}
												onChange={onChange}
												error={!!error}
												helperText={
													error ? error.message : null
												}
											/>
										)}
										rules={{
											required:
												"Please enter your password.",
										}}
									/>
								</Box>
								<Box
									display="flex"
									justifyContent="center"
									alignItems="center"
								>
									<LoadingButton
										variant="contained"
										size="large"
										color="primary"
										type="submit"
									>
										Sign in
									</LoadingButton>
								</Box>
							</Stack>
						</form>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
}
