/** @jsxImportSource @emotion/react */

import Typography from "@mui/material/Typography";

import { css } from "@emotion/react";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

import PageJumbo from "../ui-components/PageJumbo";

export default function AboutUs() {
	return (
		<>
			<PageJumbo
				title="We make digital achievable."
				desc="Our team is dedicated on making digital technologies applicable according to your needs."
				image="/static/images/stock-photos/airplanepeak.png"
			/>
			<Box
				css={css`
					background-color: #ededed;
					padding-top: 60px;
					padding-bottom: 60px;
					min-height: 300px;
				`}
			>
				<Container maxWidth="xl">
					<Typography
						gutterBottom
						variant="h2"
						component="div"
						color="common.black"
					>
						What we do
					</Typography>
					<Typography
						gutterBottom
						component="div"
						color="common.black"
						variant="nldisplay3"
					>
						<p>
							We believe that digital technologies can help
							companies achieve operational excellence and a
							seamless customer experience. In addition, new
							business areas can be discovered through the use of
							digital technologies.
						</p>
						<p>
							This requires a structured approach that includes a
							maturity assessment, prioritization of digital
							initiatives, and the implementation process.
							Novaleap's smart tools help you assess where you are
							and how to reach your goals.
						</p>
						<p>
							We are dedicated to build a fruitful digital journey
							for your organization.
						</p>
					</Typography>
				</Container>
			</Box>
			<Box
				css={css`
					background-color: #fff;
					padding-top: 60px;
					padding-bottom: 60px;
					background-image: url("/static/images/stock-photos/smb2.jpeg");
					background-size: cover;
					background-repeat: no-repeat;
					background-position: left top;
					min-height: 800px;
				`}
			>
				<Container maxWidth="xl">
					<Box>
						<Grid
							container
							spacing={2}
							alignItems="center"
							justifyContent="space-around"
						>
							<Grid item xs={12} sm={7}>
								<Box
									css={css`
										background: rgba(0, 0, 0, 0.5);
										min-height: 200px;
										display: flex;
										justify-content: center;
										padding-top: 16px;
										padding-bottom: 16px;
									`}
								>
									<Stack spacing={0}>
										<Typography
											gutterBottom
											variant="h2"
											component="div"
											color="common.white"
											align="center"
										>
											Powering SMBs
										</Typography>
										<Typography
											gutterBottom
											component="div"
											color="common.white"
											align="center"
											variant="nldisplay3"
											css={css`
												padding-left: 20px;
												padding-right: 20px;
											`}
										>
											We trust that SMBs have the
											potential for being the innovative
											force of transformation with the
											help of digital technologies. Hence,
											Novaleap has a special focus for
											SMBs in service design.
										</Typography>
									</Stack>
								</Box>
							</Grid>
						</Grid>
					</Box>
				</Container>
			</Box>
		</>
	);
}
