import React, { useContext } from "react";
import axios from "axios";

import { AuthContext } from "./AuthContext";

const RequestInterceptor = (props) => {
	const { user } = useContext(AuthContext);

	axios.interceptors.request.use(async (config) => {
		if (!user) {
			throw Error("No active account! Verify a user has been signed in.");
		}

		const token = await user.getIdToken();

		const bearer = `Bearer ${token}`;
		config.headers.Authorization = bearer;

		return config;
	});

	return <>{props.children}</>;
};

export default RequestInterceptor;
