import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import typography from "./typography";

// Create a theme instance.
let theme = createTheme({
	components: {
		// Name of the component
		MuiButtonBase: {
			defaultProps: {
				// The props to change the default for.
				disableRipple: false, // Make true for no more ripple!
			},
		},
		MuiLink: {
			defaultProps: {
				underline: "hover",
			},
		},
	},
	/*
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  */
	typography: typography,
});

theme = responsiveFontSizes(theme);

theme = createTheme(theme, {
	typography: {
		bootstrapdisplay1: {
			lineHeight: 1.2,
			fontWeight: 500,
			[theme.breakpoints.up("xs")]: {
				fontSize: "1rem",
			},
			[theme.breakpoints.up("sm")]: {
				fontSize: "2rem",
			},
			[theme.breakpoints.up("md")]: {
				fontSize: "3rem",
			},
			[theme.breakpoints.up("lg")]: {
				fontSize: "4.5rem",
			},
			[theme.breakpoints.up("xl")]: {
				fontSize: "5rem",
			},
		},
		nldisplay1: {
			lineHeight: 1.2,
			fontWeight: 500,
			[theme.breakpoints.up("xs")]: {
				fontSize: "2.3rem",
			},
			[theme.breakpoints.up("sm")]: {
				fontSize: "2.3rem",
			},
			[theme.breakpoints.up("md")]: {
				fontSize: "3.0rem",
			},
			[theme.breakpoints.up("lg")]: {
				fontSize: "4rem",
			},
			[theme.breakpoints.up("xl")]: {
				fontSize: "5rem",
			},
		},
		nldisplay2: {
			lineHeight: 1.2,
			fontWeight: 500,
			[theme.breakpoints.up("xs")]: {
				fontSize: "1rem",
			},
			[theme.breakpoints.up("sm")]: {
				fontSize: "1.15rem",
			},
			[theme.breakpoints.up("md")]: {
				fontSize: "1.5rem",
			},
			[theme.breakpoints.up("lg")]: {
				fontSize: "2.25rem",
			},
			[theme.breakpoints.up("xl")]: {
				fontSize: "2.5rem",
			},
		},		
		nldisplay3: {
			lineHeight: 1.2,
			fontWeight: 400,
			[theme.breakpoints.up("xs")]: {
				fontSize: "1rem",
			},
			[theme.breakpoints.up("sm")]: {
				fontSize: "1.15rem",
			},
			[theme.breakpoints.up("md")]: {
				fontSize: "1.3rem",
			},
			[theme.breakpoints.up("lg")]: {
				fontSize: "1.5rem",
			},
			[theme.breakpoints.up("xl")]: {
				fontSize: "1.8rem",
			},
		},			
	},
});

export default theme;
