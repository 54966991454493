import Button from "@mui/material/Button";

import { getAuth, signOut } from "@firebase/auth";

import { useNavigate } from "react-router-dom";


export default function SignOutButton() {

	let navigate = useNavigate();
	
	const signOutUser = async () => {		
		const auth = getAuth();
		signOut(auth)
			.then(() => {
				// Sign-out successful.
				navigate("/");
			})
			.catch((error) => {
				// An error happened.
			});
	};	

	return (
		<Button size="small"  variant="contained" onClick={signOutUser}>
			Sign Out
		</Button>
	);
}
