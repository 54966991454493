import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

export default function SignInButton() {
	return (
		<Button size="small" component={Link} to="/signin" variant="contained">
			Sign In
		</Button>
	);
};
