/** @jsxImportSource @emotion/react */

import NavBarActive from "../ui-components/NavBarActive";
import PageFooter from "../ui-components/PageFooter";
import FullPageAssessmentRequest from "./FullPageAssessmentRequest";



export default function AssessmentRequest() {

  return (
    <>
      <NavBarActive />

      <FullPageAssessmentRequest></FullPageAssessmentRequest>

      <PageFooter></PageFooter>
    </>
  );
}
