/** @jsxImportSource @emotion/react */

import Typography from "@mui/material/Typography";

import { css } from "@emotion/react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { TextField, Button } from "@mui/material";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useCreateContactFormMutation } from "../services/saturnAPI";

import { Controller, useForm } from "react-hook-form";

import { Link as RouterLink} from "react-router-dom";
import Link from "@mui/material/Link";

import PageJumbo from "../ui-components/PageJumbo";

export default function ContactUs() {
	const {
		handleSubmit,
		setError,
		reset,
		clearErrors,
		control,
		formState: { errors },
	} = useForm({
		defaultValues: {
			fullName: "",
			email: "",
			organizationName: "",
			phoneNumber: "",
			message: "",
		},
	});

	const [
		createContactForm,
		{
			error: createContactFormIsError,
			isLoading: createContactFormIsLoading,
		},
	] = useCreateContactFormMutation();

	const onSubmit = (formData, e) => {
		e.preventDefault();
		createContactForm({
			fullName: formData.fullName.trim(),
			email: formData.email.trim(),
			organizationName: formData.organizationName.trim(),
			phoneNumber: formData.phoneNumber.trim(),
			message: formData.message.trim(),
		})
			.unwrap()
			.then((payload) => {
				toast.success(
					"Thank you for your message. We will contact you shortly.",
					{
						autoClose: false,
						position: "bottom-right",
					}
				);
				reset();
			})
			.catch((error) => {
				toast.warn(error.response, { autoClose: false, position: "bottom-right" });
			});
	};

	return (
		<>
			<PageJumbo
				title="Contact us"
				desc="We are ready to help you."
				image="/static/images/stock-photos/contact-us4.jpeg"
			/>
			<Container
				maxWidth="xl"
				css={css`
					padding-top: 32px;
					padding-bottom: 48px;
				`}
			>
				<form onSubmit={handleSubmit(onSubmit)}>
					<ToastContainer />
					<Grid container spacing={2} justifyContent="center">
						<Grid item xs={12} sm={3}>
							<Controller
								name="fullName"
								control={control}
								defaultValue=""
								render={({
									field: { onChange, value },
									fieldState: { error },
								}) => (
									<TextField
										label="Full name"
										fullWidth
										size="small"
										value={value}
										onChange={onChange}
										error={!!error}
										helperText={
											error ? error.message : null
										}
									/>
								)}
								rules={{ required: "Full name is required." }}
							/>
						</Grid>
						<Grid item xs={12} sm={3}>
							<Controller
								name="email"
								control={control}
								defaultValue=""
								render={({
									field: { onChange, value },
									fieldState: { error },
								}) => (
									<TextField
										label="E-mail"
										fullWidth
										size="small"
										value={value}
										onChange={onChange}
										error={!!error}
										helperText={
											error ? error.message : null
										}
									/>
								)}
								rules={{
									required: "E-mail is required.",
									pattern: {
										value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
										message:
											"Please enter a valid e-mail address.",
									},
								}}
							/>
						</Grid>
					</Grid>
					<Grid
						container
						spacing={2}
						justifyContent="center"
						sx={{ pt: 2 }}
					>
						<Grid item xs={12} sm={3}>
							<Controller
								name="organizationName"
								control={control}
								defaultValue=""
								render={({
									field: { onChange, value },
									fieldState: { error },
								}) => (
									<TextField
										label="Organization name"
										fullWidth
										size="small"
										value={value}
										onChange={onChange}
										error={!!error}
										helperText={
											error ? error.message : null
										}
									/>
								)}
								rules={{
									required: "Organization name is required.",
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={3}>
							<Controller
								name="phoneNumber"
								control={control}
								defaultValue=""
								render={({
									field: { onChange, value },
									fieldState: { error },
								}) => (
									<TextField
										label="Phone number"
										fullWidth
										size="small"
										value={value}
										onChange={onChange}
										error={!!error}
										helperText={
											error ? error.message : null
										}
									/>
								)}
								rules={{
									required: "Phone number is required.",
								}}
							/>
						</Grid>
					</Grid>
					<Grid
						container
						spacing={2}
						justifyContent="center"
						sx={{ pt: 2 }}
					>
						<Grid item xs={12} sm={6}>
							<Controller
								name="message"
								control={control}
								defaultValue=""
								render={({
									field: { onChange, value },
									fieldState: { error },
								}) => (
									<TextField
										label="Message"
										fullWidth
										size="small"
										value={value}
										onChange={onChange}
										multiline
										rows={4}
										error={!!error}
										helperText={
											error ? error.message : null
										}
									/>
								)}
								rules={{
									required: "Please enter your message.",
								}}
							/>
						</Grid>
					</Grid>
					<Grid
						container
						spacing={2}
						justifyContent="center"
						sx={{ pt: 2 }}
					>
						<Grid item xs={12} sm={6} align="center">
							<Typography
								gutterBottom
								component="div"
								css={css`
									font-size: 11px;
								`}
							>
								<p>
									By supplying my contact information, I
									authorize Novaleap to contact me with
									personalized communications about products
									and services.
								</p>
								See our{" "}
								<Link component={RouterLink} to="/privacy-policy">Privacy Policy</Link>{" "}
								for more details.
							</Typography>
						</Grid>
					</Grid>
					<Grid
						container
						spacing={2}
						justifyContent="center"
						sx={{ pt: 2 }}
					>
						<Grid item xs={12} sm={6} align="center">
							<Button
								variant="contained"
								size="large"
								color="primary"
								type="submit"
							>
								Submit
							</Button>
						</Grid>
					</Grid>
				</form>
			</Container>
		</>
	);
}
