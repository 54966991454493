import { useEffect, useState } from "react";

import Typography from "@mui/material/Typography";

import React, { useContext } from "react";
import { AuthContext } from "../utils/AuthContext";

export default function WelcomeName() {
	const { user } = useContext(AuthContext);

	const [name, setName] = useState(null);

	useEffect(() => {
		if (user) {
			setName(user.email);
		}
	}, [user]);

	if (user && name) {
		return <Typography variant="h6">Welcome, {name}</Typography>;
	} else {
		return null;
	}
};
