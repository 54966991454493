/** @jsxImportSource @emotion/react */

import Typography from "@mui/material/Typography";

import { css } from "@emotion/react";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

import PageJumbo from "../ui-components/PageJumbo";

import { Link } from "react-router-dom";

import { Link as RouterLink } from "react-router-dom";

export default function DigitalConsulting() {
	return (
		<>
			<PageJumbo
				title="Digital Consulting"
				desc="Digital transformation doesn't happen in a snap of fingers."
				image="/static/images/stock-photos/digital-consulting2.jpeg"
			/>
			<Box
				css={css`
					background-color: #ededed;
					padding-top: 18px;
					padding-bottom: 18px;
					min-height: 300px;
				`}
			>
				<Container maxWidth="xl">
					<Typography
						gutterBottom
						component="div"
						color="common.black"
						variant="h3"
					>
						Digital Transformation
					</Typography>
					<Typography
						gutterBottom
						component="div"
						color="common.black"
						variant="body1"
					>
						<p>
							Today, digital technologies have a great impact on
							business. Companies that make use of digital
							technologies achieve optimized business processes
							and improved customer experience. We help our
							clients discover the power of data and AI, build
							transformation roadmaps and implement projects that
							automate operations, improve customer experience and
							accelerate digital growth.
						</p>
						<p>
							Digital transformation doesn't happen in a snap of
							fingers. It starts with a digital business strategy
							where transformation goals and the desired state of
							the business are set clearly. Initiatives for
							process improvements and new digital
							products/services compose the roadmap. New ways of
							working such as agile has a great potential for
							productivity. Applying these work styles by taking
							care of the organization culture is a key success
							factor for a sustainable transformation.
						</p>
						<p>
							A solid governance model is required for the success
							of digital strategy execution. Identifying the
							critical indicators and monitoring them assures that
							progress is on track. Almost all transformation
							programs need full support from senior management
							and digital transformation is not an exception.
							Therefore, providing the necessary tools to senior
							management for monitoring digital transformation is
							important.
						</p>
					</Typography>
					<Typography
						gutterBottom
						component="div"
						color="common.black"
						variant="h3"
					>
						The Age of Data
					</Typography>
					<Typography
						gutterBottom
						component="div"
						color="common.black"
						variant="body1"
					>
						<p>
							In the complex business world where competition is
							intense, many companies have realized that data is
							the source that will lead them to success in
							creative solutions, customer satisfaction and future
							planning. Companies that can offer their customers
							the most accurate service and solutions through the
							correct analysis and processing of this data can
							thus lay their future on a solid foundation.
						</p>
						<p>
							Data, which has become the most valuable resource of
							our time, allows companies to differentiate
							themselves. For this reason, companies strive to
							store large amounts of data and even realize an
							intelligent information system by making sense of
							it. Numerous reports, mathematical analyzes and
							statistical information have taken their place among
							the factors that most influence decision-making in
							today's business world.
						</p>
						<p>
							Regardless of its size every company has data and
							there are many opportunities that can be realized by
							utilizing this data.
						</p>
					</Typography>
					<Typography
						gutterBottom
						component="div"
						color="common.black"
						variant="h3"
					>
						Our Approach
					</Typography>
					<Typography
						gutterBottom
						component="div"
						color="common.black"
						variant="body1"
					>
						<p>
							Being aware of the fact that digital transformation
							is a long-term journey, we stand by our customers as
							a reliable partner throughout this journey. We are
							constantly improving our toolset to reflect new
							trends, technologies and experiences.
						</p>
						<p>Join today and accelerate your transformation.</p>
					</Typography>
					<Button
						size="large"
						component={RouterLink}
						to="/request-assessment"
						variant="contained"
					>
						Get Started
					</Button>
				</Container>
			</Box>
			<Box
				css={css`
					background-color: #fff;
					padding-top: 60px;
					padding-bottom: 60px;
					background-image: url("/static/images/stock-photos/demand-planning1.jpeg");
					background-size: cover;
					background-repeat: no-repeat;
					background-position: left top;
					min-height: 800px;
				`}
			>
				<Container maxWidth="xl">
					<Box>
						<Grid
							container
							spacing={2}
							alignItems="center"
							justifyContent="flex-end"
						>
							<Grid item xs={12} sm={5}>
								<Box
									css={css`
										background: rgba(0, 0, 0, 0.5);
										min-height: 200px;
										display: flex;
										justify-content: center;
										padding-top: 16px;
										padding-bottom: 16px;
									`}
								>
									<Stack spacing={0}>
										<Typography
											gutterBottom
											variant="h4"
											component="div"
											color="common.white"
											align="center"
										>
											Example Scenario: Machine Learning
											in Demand Planning
										</Typography>
										<Typography
											gutterBottom
											component="div"
											color="common.white"
											align="center"
											variant="body1"
											css={css`
												padding-left: 20px;
												padding-right: 20px;
											`}
										>
											<p>
												Using the power of machine
												learning within the field of
												demand planning provides a
												decision-making support for many
												industries. Companies which
												utilizes machine learning
												algorithms in demand planning
												produce forecasts much more
												accurate than possible with
												traditional methods.
											</p>
											<p>
												That brings up an optimized
												inventory management, better
												process automation and customer
												experience. No more "sold-out"s
												or warehouses with overstock.
											</p>
										</Typography>
									</Stack>
								</Box>
							</Grid>
						</Grid>
					</Box>
				</Container>
			</Box>
		</>
	);
}
