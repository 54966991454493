/** @jsxImportSource @emotion/react */

import Typography from "@mui/material/Typography";

import { css } from "@emotion/react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { Stack } from "@mui/material";

import { Link } from "@mui/material";

import { Link as RouterLink } from "react-router-dom";

import styled from "@emotion/styled";
import { color } from "@mui/system";

export default function Public404() {
	return (
		<Box
			css={css`
				padding-top: 60px;
				padding-bottom: 30px;
				min-height: calc(100vh - 388px);
			`}
		>
			<Container maxWidth="xl">
				<Grid
					container
					spacing={0}
					justifyContent="space-around"
					alignItems="center"
				>
					<Grid
						item
						xs={12}
						sm={6}
						css={css`
							background-color: #fff;
							padding-bottom: 24px;
						`}
					>
						<Typography
							variant="nldisplay1"
							gutterBottom
							component="div"
							align="center"
						>
							404
						</Typography>

						<Typography
							variant="h2"
							gutterBottom
							component="div"
							align="center"
							css={css`
								color: #4b4b4b;
							`}
						>
							OPPS! PAGE NOT FOUND
						</Typography>
						<Typography
							variant="body1"
							gutterBottom
							component="div"
							align="center"
							css={css`
								color: #4b4b4b;
							`}
						>
							Sorry, the page you're looking for doesn't exist. If
							you think something is broken please contact us.
						</Typography>
					</Grid>
					<Grid
						container
						spacing={0}
						justifyContent="center"
						alignItems="center"
					>
						<Button
							size="medium"
							component={RouterLink}
							to="/"
							variant="contained"
							css={css`
								margin-right: 24px;
							`}
						>
							Return Home
						</Button>
						<Button
							size="medium"
							component={RouterLink}
							to="/contact"
							variant="contained"
						>
							Contact Us
						</Button>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
}
