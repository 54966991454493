export default {
	button: {
		textTransform: "none",
	},
	fontFamily: ["Roboto", "Open Sans", "Inter"].join(","),
	h1: {		
		fontSize: '2.5rem',
		fontWeight: 500,
	},
	h2: {
		fontSize: '2rem',
		fontWeight: 500,
	},
	h3: {
		fontSize: '1.75rem',
		fontWeight: 500,
	},
	h4: {
		fontSize: '1.5rem',
		fontWeight: 500,
	},
	h5: {
		fontSize: '1.25rem',
		fontWeight: 500,
	},
	h6: {
		fontSize: '1rem',
		fontWeight: 500,
	},
	display1: {
		fontSize: '5rem',
		fontWeight: 500,
		lineHeight: 1.2,
	},
};
