/** @jsxImportSource @emotion/react */

import Typography from "@mui/material/Typography";

import { css } from "@emotion/react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import { useTheme} from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

export default function PageJumbo(props) {

	const theme = useTheme();
	const smallScreen = useMediaQuery(theme.breakpoints.only('xs'));	
	
	return (
		<>
			<Box
				css={css`
					background-image: url("${props.image}");
					min-height: ${smallScreen ? `272px` : `372px`};
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center top;
				`}
			>
				<Box
					css={css`
						background: rgba(0, 0, 0, 0.5);
						min-height: ${smallScreen ? `272px` : `372px`};
						display: flex;
						justify-content: center;
					`}
				>
					<Stack
						direction="column"
						justifyContent="center"
						alignItems="center"
						spacing={2}
					>
						<Typography
							gutterBottom
							variant="nldisplay1"
							component="h1"
							align="center"
							css={css`
								color: #fff;
							`}
						>
							{props.title}
						</Typography>
						<Typography
							gutterBottom
							component="div"
							align="center"
							variant="body1"
							css={css`
								color: #fff;
							`}
						>
							{props.desc}
							{smallScreen}
						</Typography>
					</Stack>
				</Box>
			</Box>
		</>
	);
}
