/** @jsxImportSource @emotion/react */

import Typography from "@mui/material/Typography";

import { css } from "@emotion/react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import { Card, CardActionArea, CardMedia, CardContent } from "@mui/material";

export default function LandingFeatures() {
	return (
		<>
			<Container maxWidth="xl">
				<Grid
					container
					spacing={8}
					alignItems="center"
					css={css`
						padding-top: 64px;
						padding-bottom: 64px;
					`}
				>
					<Grid item xs={12} sm={4}>
						<Card
							css={css`
								position: relative;
								pointer-events: none;								
							`}
						>							
								<CardMedia
									component="img"
									height="440"
									image="/static/images/stock-photos/agile2.jpeg"
									alt="green iguana"
								/>
								<CardContent
									css={css`
										position: absolute;
										top: 0;
										left: 0;
										background: rgb(0, 0, 0);
										background: linear-gradient(
											180deg,
											rgba(0, 0, 0, 1) 0%,
											rgba(0, 0, 0, 1) 35%,
											rgba(0, 0, 0, 0) 100%
										);
									`}
								>
									<Box
										css={css`
											padding: 20px;
										`}
									>
										<Typography
											gutterBottom
											variant="h2"
											component="div"
											color="common.white"
										>
											Complete structure
										</Typography>
										<Typography
											variant="body1"
											color="white"
										>
											Novaleap provides a well-designed
											framework that covers all aspects of
											digital such as sustainibility, new
											ways of working and cybersecurity.
										</Typography>
									</Box>
								</CardContent>							
						</Card>
					</Grid>

					<Grid item xs={12} sm={4}>
						<Card
							css={css`
								position: relative;
								pointer-events: none;
							`}
						>
							<CardActionArea>
								<CardMedia
									component="img"
									height="440"
									image="/static/images/stock-photos/governance.jpeg"
									alt="green iguana"
								/>
								<CardContent
									css={css`
										position: absolute;
										top: 0;
										left: 0;
										background: rgb(0, 0, 0);
										background: linear-gradient(
											180deg,
											rgba(0, 0, 0, 1) 0%,
											rgba(0, 0, 0, 1) 35%,
											rgba(0, 0, 0, 0) 100%
										);
									`}
								>
									<Box
										css={css`
											padding: 20px;
										`}
									>
										<Typography
											gutterBottom
											variant="h2"
											component="div"
											color="common.white"
										>
											Smart toolset
										</Typography>
										<Typography
											variant="body1"
											color="white"
										>
											Comprehensive assessments and
											benchmarks performed easily with AI
											powered tools.
										</Typography>
									</Box>
								</CardContent>
							</CardActionArea>
						</Card>
					</Grid>

					<Grid item xs={12} sm={4}>
						<Card
							css={css`
								position: relative;
								pointer-events: none;
							`}
						>
							<CardActionArea>
								<CardMedia
									component="img"
									height="440"
									image="/static/images/stock-photos/expertise2.jpeg"
									alt="green iguana"
								/>
								<CardContent
									css={css`
										position: absolute;
										top: 0;
										left: 0;
										background: rgb(0, 0, 0);
										background: linear-gradient(
											180deg,
											rgba(0, 0, 0, 1) 0%,
											rgba(0, 0, 0, 1) 35%,
											rgba(0, 0, 0, 0) 100%
										);
									`}
								>
									<Box
										css={css`
											padding: 20px;
										`}
									>
										<Typography
											gutterBottom
											variant="h2"
											component="div"
											color="common.white"
										>
											Expertise when needed
										</Typography>
										<Typography
											variant="body1"
											color="white"
										>
											When you need more detailed study
											about a specific subject Novalep
											connects you to the skilled people.
										</Typography>
									</Box>
								</CardContent>
							</CardActionArea>
						</Card>
					</Grid>
				</Grid>
			</Container>
		</>
	);
}
