import axios from "axios";

import { ToastContainer, toast } from "react-toastify";

axios.interceptors.response.use(undefined, error => { 
  //first param is when successful req, second when promise rejected
  if (error.message === 'Network Error' && !error.response) {
      toast.error('Network Error - make sure the API is running!');
  }
  const {status, data, config} = error.response;
  if (status === 404) {
    toast.error('Not Found!');
  }
  if (status === 400 && config.method === 'get' && data.errors.hasOwnProperty('id')) {
    toast.error('Not Found!');
  }
  if (status === 500) {
      toast.error('Server error - check the terminal for more info!');
  }
  throw error.response;
})

const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: '' }) =>
  async ({ url, method, data }) => {
    try {
      const result = await axios({ url: baseUrl + url, method, data })
      return { data: result.data }
    } catch (axiosError) {
      let err = axiosError
      return {
        error: { status: err.response?.status, data: err.response?.data },
      }
    }
  }

export default axiosBaseQuery