/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import SignInButton from "./SignInButton";
import SignOutButton from "./SignOutButton";

import { AuthContext } from "../utils/AuthContext";

import { useContext } from "react";
import Box from "@mui/material/Box";

export default function SignInSignOutButton() {
	const { user, initializing } = useContext(AuthContext);

	if (user) {
		return <SignOutButton />;
	} else if (!initializing) {
		return <SignInButton />;
	} else {
		return null;
	}
}
