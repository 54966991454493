/** @jsxImportSource @emotion/react */

import Typography from "@mui/material/Typography";

import { css } from "@emotion/react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import { Stack } from "@mui/material";

import { Link } from "@mui/material";

import { Link as RouterLink } from "react-router-dom";

import styled from "@emotion/styled";

const FooterSectionHeader = styled(Link)`
	color: #4b4b4b;
	font-weight: 600;
	pointer-events: none;
`;

const FooterLink = styled(Link)`
	color: #4b4b4b;
	text-decoration: none;

	:hover {
		color: #999999;
		text-decoration: none;
	}
`;

export default function PageFooter() {
	return (
		<>
			<Box
				css={css`
					background-color: #fafafa;
					padding-top: 60px;
					padding-bottom: 30px;
					min-height: 324px;					
				`}
			>
				<Container maxWidth="xl">
					<Grid container spacing={6} justifyContent="space-around">
						<Grid item xs={12} sm={2}>
							<Stack spacing={2}>
								<div>
									<FooterSectionHeader>
										Novaleap
									</FooterSectionHeader>
								</div>
								<div>
									<FooterLink
										component={RouterLink}
										to="/about"
									>
										About
									</FooterLink>
								</div>
								<div>
									<FooterLink
										component={RouterLink}
										to="/contact"
									>
										Contact us
									</FooterLink>
								</div>
							</Stack>
						</Grid>
						<Grid item xs={12} sm={2}>
							<Stack spacing={2}>
								<div>
									<FooterSectionHeader>
										Services
									</FooterSectionHeader>
								</div>
								<div>
									<FooterLink
										component={RouterLink}
										to="/digital-consulting"
									>
										Digital consulting
									</FooterLink>
								</div>
								<div>
									<FooterLink
										component={RouterLink}
										to="/cybersecurity"
									>
										Cybersecurity
									</FooterLink>
								</div>
							</Stack>
						</Grid>
						<Grid item xs={12} sm={2}>
							<Stack spacing={2}>
								<div>
									<FooterSectionHeader>
										Follow us
									</FooterSectionHeader>
								</div>
								<div>
									<FooterLink
										component={Link}
										href="https://twitter.com/UseNovaleap"
									>
										Twitter
									</FooterLink>
								</div>
							</Stack>
						</Grid>
					</Grid>
					<Box sx={{ textAlign: "center", paddingTop: "40px" }} display={{ xs: "none", sm:"block" }}>
						<ul
							css={css`
								list-style-type: none;
								list-style: none;
								padding: 0;
								align: center;
								display: flex;
								justify-content: center;
								li {
									float: left;
									font-size: 12px;
								}
							`}
						>
							<li>
								<Box
									css={css`
										display: inline-block;
										&:after {
											margin: 0 8px;
											content: "|";
										}
									`}
								>
									Copyright © 2022 Novaleap. All rights
									reserved.
								</Box>
							</li>
							<li>
								<FooterLink
									component={RouterLink}
									to="/privacy"
								>
									<Box
										component="span"
										css={css`
											display: inline-block;
											&:after {
												margin: 0 8px;
												content: "|";
											}
										`}
									>
										Privacy policy
									</Box>
								</FooterLink>
							</li>
							<li>
								<FooterLink component={RouterLink} to="/terms">
								<Box
										component="span"
										css={css`
											display: inline-block;
										`}
									>
										Terms of Use
									</Box>									
								</FooterLink>
							</li>
						</ul>
					</Box>
					<Box sx={{ textAlign: "center", paddingTop: "40px" }} display={{ xs: "block", sm:"none" }}>
					<Box
									css={css`
										display: inline-block;
										padding-bottom: 8px;
									`}
								>
									Copyright © 2022 Novaleap. All rights
									reserved.
								</Box>
								<Box>
								<FooterLink
									component={RouterLink}
									to="/privacy"
								>
									<Box
										component="span"
										css={css`
											display: inline-block;
											padding-bottom: 8px;
										`}
									>
										Privacy policy
									</Box>
								</FooterLink>	
											</Box>
											<Box>
								<FooterLink component={RouterLink} to="/terms">
								<Box
										component="span"
										css={css`
											display: inline-block;
										`}
									>
										Terms of Use
									</Box>									
								</FooterLink>		
								</Box>													
					</Box>
				</Container>
			</Box>
		</>
	);
}
