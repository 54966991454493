/** @jsxImportSource @emotion/react */

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { css } from "@emotion/react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import { Link as RouterLink } from "react-router-dom";

export default function LandingFullPageWelcome() {

  const pictureArray = [
    "/static/images/stock-photos/people1.jpeg",
    "/static/images/stock-photos/runner1.jpeg",
    "/static/images/stock-photos/amsterdam-night1.jpeg",
    "/static/images/stock-photos/people4.jpeg",
  ];

  const randomIndex = Math.floor(Math.random() * pictureArray.length);

  return (
    <>
      <Box
        css={css`
          position: relative;
          z-index: 1;
          &:before {
            background-image: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0) 0%,
              rgba(21, 27, 38, 0.5) 100%
            );
            background-repeat: repeat-x;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 100%;
            content: "";
          }
          &:after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 100%;
            content: "";
          }

          background-image: url("${pictureArray[randomIndex]}");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: top center;
        `}
      >
        <Container
          maxWidth="xl"
          css={css`
            height: 100vh;
            padding-top: 64px;
          `}
        >
          <Grid
            container
            spacing={0}
            justifyContent="center"
            alignItems="center"
            css={css`
              height: 100vh;
            `}
          >
            <Grid item md={10}>
              <Typography
                variant="nldisplay1"
                gutterBottom
                component="h1"
                css={css`
                  color: #fff;
                `}
              >
                The platform for digital progress
              </Typography>

              <Typography
                variant="nldisplay2"
                gutterBottom
                component="div"
                css={css`
                  color: #fff;
                `}
              >
                Novaleap helps you to discover the potential of digital
                technologies and accelerate the transformation.
              </Typography>

              <Button
                size="large"
                component={RouterLink}
                to="/request-assessment"
                variant="contained"
              >
                Get Started
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
