/** @jsxImportSource @emotion/react */

import Typography from "@mui/material/Typography";

import { css } from "@emotion/react";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

import PageJumbo from "../ui-components/PageJumbo";

import { Link } from "react-router-dom";

import { Link as RouterLink } from "react-router-dom";

export default function Cybersecurity() {
	return (
		<>
			<PageJumbo
				title="Cybersecurity"
				desc="Cyber resilience is the most important factor for sustainable success in digital business."
				image="/static/images/stock-photos/cybersecurity1.jpeg"
			/>
			<Box
				css={css`
					background-color: #ededed;
					padding-top: 18px;
					padding-bottom: 18px;
					min-height: 300px;
				`}
			>
				<Container maxWidth="xl">
					<Typography
						gutterBottom
						component="div"
						color="common.black"
						variant="h3"
					>
						Cybersecurity Governance
					</Typography>
					<Typography
						gutterBottom
						component="div"
						color="common.black"
						variant="body1"
					>
						<p>
							Information is an important resource of digital
							businesses and must be protected. Cybersecurity
							governance is an integral part of corporate
							governance and it should be implemented at all
							levels of an organization. Cybersecurity governance
							can be summarized as a series of activities and
							responsibilities aimed at meeting the needs of all
							stakeholders and achieving the goals set by a
							company. Cybersecurity governance should contribute
							to the following goals:
						</p>
						<ul>
							<li>
								Strategically aligning information security with
								business strategy to support organizational
								goals.
							</li>
							<li>
								Effective risk management by implementing
								appropriate measures to manage and mitigate
								risks and reduce potential impacts on
								information resources to an acceptable level.
							</li>
							<li>
								Delivering value to the customer by optimizing
								information security investments to support
								organizational goals.
							</li>
							<li>
								Resource management by using information
								security and infrastructure efficiently and
								effectively.
							</li>
							<li>
								Measuring performance by measuring, monitoring
								and reporting information security governance
								metrics to ensure organizational goals are met.
							</li>
						</ul>
						<p>
							The characteristics of an effective cybersecurity
							governance:
						</p>
						<p>
							The whole company participates: The assets to be
							protected are known and the security level is
							defined. Information security is considered an
							indispensable element by business units. Security
							measures support business operations.
						</p>
						<p>
							Responsibilities are defined: The management is
							involved in decision-making on security programs.
							Business units verify the security measures that
							support their operations, projects and development
							strategy. Data and process owners are identified.
							Information Security professionals implement
							programs according to the defined strategies and
							policies.
						</p>
						<p>
							The level of protection depends on risk appetite:
							Information security risks are assessed and
							systematically mitigated. Risk appetite is defined
							and proactive risk management is carried out with
							all company activities, both operations and change
							projects. Information security controls are managed
							and associated with risks.
						</p>
						<p>
							Security is actively managed: Security strategy,
							policies and rules are established to serve the
							needs of the organization. The assets and
							responsibilities to be protected are defined.
							Management allocates adequate resources according to
							an iterative process of evaluation and decision
							making. Responsibilities are defined at each level.
							The reporting system supports the decision making
							process and is based on key indicators. Incident
							management system is implemented. Employees are
							trained and aware of the risks. The security program
							is audited and adjusted to the company's needs.
						</p>
					</Typography>
					<Typography
						gutterBottom
						component="div"
						color="common.black"
						variant="h3"
					>
						Architecture
					</Typography>
					<Typography
						gutterBottom
						component="div"
						color="common.black"
						variant="body1"
					>
						<p>
							Security architecture refers to the set of
							disciplines used to design solutions to address
							security requirements or a comprehensive framework
							used to achieve these goals. Enterprise security
							architecture is a fundamental building block of
							enterprise information systems architecture. Rather
							than focusing on individual functional and
							non-functional components in a single application,
							it focuses on a long-term and strategic design for a
							set of security services that can be used by systems
							or business processes in multiple applications.
						</p>
						<p>Security architecture</p>
						<ul>
							<li>represents a simple, long-term control view</li>
							<li>
								provides a unified vision for common security
								controls
							</li>
							<li>leverages existing technology investments</li>
							<li>
								provides a flexible approach to current and
								future threats
							</li>
							<li>
								focuses on the needs of the core functions of
								the organization
							</li>
						</ul>
					</Typography>
					<Typography
						gutterBottom
						component="div"
						color="common.black"
						variant="h3"
					>
						Improve Your Security Level with Novaleap
					</Typography>
					<Typography
						gutterBottom
						component="div"
						color="common.black"
						variant="body1"
					>
						<p>
							We provide tools and consultancy services to build
							an effective cybersecurity model aligned with your
							business goals for your existing technology
							infrastructure using up-to-date technological
							products, methods and policies.
						</p>
					</Typography>
					<Button
						size="large"
						component={RouterLink}
						to="/request-assessment"
						variant="contained"
					>
						Get Started
					</Button>
				</Container>
			</Box>
			<Box
				css={css`
					background-color: #fff;
					padding-top: 60px;
					padding-bottom: 60px;
					background-image: url("/static/images/stock-photos/sans1.jpeg");
					background-size: cover;
					background-repeat: no-repeat;
					background-position: left top;
					min-height: 800px;
				`}
			>
				<Container maxWidth="xl">
					<Box>
						<Grid
							container
							spacing={2}
							alignItems="center"
							justifyContent="flex-end"
						>
							<Grid item xs={12} sm={5}>
								<Box
									css={css`
										background: rgba(0, 0, 0, 0.5);
										min-height: 200px;
										display: flex;
										justify-content: center;
										padding-top: 16px;
										padding-bottom: 16px;
									`}
								>
									<Stack spacing={0}>
										<Typography
											gutterBottom
											variant="h4"
											component="div"
											color="common.white"
											align="center"
										>
											SANS CIS Security Controls
										</Typography>
										<Typography
											gutterBottom
											component="div"
											color="common.white"
											align="center"
											variant="body1"
											css={css`
												padding-left: 20px;
												padding-right: 20px;
											`}
										>
											<p>
												Critical cyber security
												controls, prepared by SANS
												Institute are globally accepted.
												Taking the necessary security
												measures in line with these
												controls will make a significant
												positive contribution to the
												level of cyber security.
											</p>
										</Typography>
									</Stack>
								</Box>
							</Grid>
						</Grid>
					</Box>
				</Container>
			</Box>
		</>
	);
}
