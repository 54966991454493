/** @jsxImportSource @emotion/react */

import React, { useContext } from "react";

import { css } from "@emotion/react";

import Box from "@mui/material/Box";

import { AuthContext } from "../../utils/AuthContext";

export default function Main() {
	const { user } = useContext(AuthContext);

	return (

			<>
			<Box>Welcome</Box>{/* Data may be presented here */}
			</>

	);
}
