/** @jsxImportSource @emotion/react */

import Typography from "@mui/material/Typography";

import { css } from "@emotion/react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

export default function LandingNumbers() {
	const numbersArray = [
		{
			key: 0,
			num: "23x",
			desc: "Data-driven businesses outperform their peers in customer acquisition at a rate of 23 times more than their competitors.",
			pub: "Forbes",
		},
		{
			key: 1,
			num: "84%",
			desc: "84% of business executives say that new business opportunities have emerged as their companies make digital transformations.",
			pub: "HBR",
		},
		{
			key: 2,
			num: "63%",
			desc: "With the support of the technology that enables remote work, 63% of high-growth companies have enabled productivity-anywhere workforce models.",
			pub: "Accenture",
		},
		{
			key: 3,
			num: "37%",
			desc: "37% of IT initiatives by companies worldwide are focused on improving customer experience.",
			pub: "Flexera",
		},
		{
			key: 4,
			num: "75%",
			desc: "By 2025, 3 out of every 4 business leaders will leverage digital platforms to adapt to new markets and industries.",
			pub: "IDC",
		},
		{
			key: 5,
			num: "95%",
			desc: "95% of IT directors feel that technology will help them tackle future challenges.",
			pub: "Forbes",
		},
		{
			key: 6,
			num: "69%",
			desc: "69% of higher maturity companies are using digital technologies to cut carbon emissions.",
			pub: "Deloitte",
		},
		{
			key: 7,
			num: "25%",
			desc: "25% of organizations worldwide have adopted cloud-distributed technology on a large scale.",
			pub: "KPMG",
		},
	];

	return (
		<>
			<Box
				css={css`
					background-color: #f2f4f8;
					padding-top: 60px;
					padding-bottom: 60px;
				`}
			>
				<Container maxWidth="xl">
					<Typography
						gutterBottom
						variant="h1"
						component="div"
						align="center"
					>
						Impact in numbers
					</Typography>
					<Box>
						<Grid
							container
							spacing={8}
							alignItems="center"
							css={css`
								padding-top: 12px;
							`}
						>
							{numbersArray.map((item, index) => (
								<Grid item xs={12} sm={3} key={item.key}>
									<Typography
										align="center"
										gutterBottom
										variant="h1"
										component="div"
									>
										{item.num}
									</Typography>
									<Typography
										align="center"
										gutterBottom
										component="div"
										variant="body1"
									>
										{item.desc}
									</Typography>
								</Grid>
							))}
						</Grid>
					</Box>
				</Container>
			</Box>
		</>
	);
}
